import React from "react";
import "./index.css";
import "./bounce.css";
import "./spiral.css";

class App extends React.Component{
    render() {
        return (
            <div></div> )
    };
}

export default App;